import React from "react";

const MainLogo = () => {
    return (
        <div className="main-logo">
            <span className="tool-logo">Leetcode Solution Explorer</span>{" "}
            <span className="tool-credit">
                {`Tool & solutions by `}
                <a className="tool-credit-url" href="https://anthony.media">
                    @anthonyg94
                </a>
            </span>
        </div>
    );
};

export default MainLogo;
