import React from "react";
import "./ProblemCard.css";

const ProblemCard = ({ problem, onProblemClick, activeProblem }) => {
    const { id } = problem;
    return (
        <li
            onClick={onProblemClick}
            className={`problem-card ${activeProblem ? "active-problem" : ""}`}
        >
            {id}
        </li>
    );
};

export default ProblemCard;
