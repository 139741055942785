import React from "react";
import "./ProblemDisplay.css";
import ReaderPane from "./ReaderPane/ReaderPane";
import SolutionPane from "./SolutionPane/SolutionPane";
const ProblemDisplay = ({ readme, solution }) => {
    return (
        <div className="problem-display">
            <ReaderPane readme={readme} />
            <SolutionPane solution={solution} />
        </div>
    );
};
export default ProblemDisplay;
