import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./components/Root/App";
import data from "./localData";

ReactDOM.render(
    <React.StrictMode>
        <App data={data} />
    </React.StrictMode>,
    document.getElementById("root")
);
