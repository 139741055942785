import axios from "axios";
import { useEffect, useState } from "react";
import AppBar from "../AppBar/AppBar";
import ProblemDisplay from "../ProblemDisplay/ProblemDisplay";
import ProblemList from "../ProblemList/ProblemList";
import "./App.css";
function App({ data }) {
    const [problem, setProblem] = useState(data[0]);
    const [readme, setReadme] = useState(null);
    const [solution, setSolution] = useState(null);

    const handleSetProblem = (prob) => {
        if (prob?.id === problem?.id) {
            return;
        }
        setProblem(prob);
        console.log(`Problem set to: ${prob?.id}`);
        //fetchProblemData(prob);
    };
    const fetchProblemData = async (prob) => {
        const pathReadme = `./problems/${prob?.id}/readme.md`;
        const pathSolution = `./problems/${prob?.id}/${prob?.id}.js`;
        //console.log(`Fetching readme for ${prob?.id}`);
        //console.log(pathReadme);
        try {
            const { data } = await axios.get(pathReadme);
            setReadme(data);
            //console.log(data);
        } catch (err) {
            //console.log("error fetching readme: ", err);
        }
        //console.log(`Fetching solution for ${prob?.id}`);
        try {
            const { data } = await axios.get(pathSolution);
            setSolution(data);
            //console.log(data);
        } catch (err) {
            //console.log("error fetching readme: ", err);
        }
    };
    useEffect(() => {
        fetchProblemData(problem);
    }, [problem]);
    return (
        <div className="App problems-container">
            <AppBar />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "calc(100vh - 100px)",
                }}
            >
                <ProblemList
                    problems={data}
                    activeProblem={problem}
                    onProblemClick={handleSetProblem}
                />
                <ProblemDisplay readme={readme} solution={solution} />
            </div>
        </div>
    );
}

export default App;
