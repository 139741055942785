import React from "react";
import "./SolutionPane.css";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const CodeComponent = ({ codeString }) => {
    return (
        <SyntaxHighlighter
            language="javascript"
            style={docco}
            wrapLines
            wrapLongLines
            showLineNumbers
        >
            {codeString}
        </SyntaxHighlighter>
    );
};
const SolutionPane = ({ solution }) => {
    return (
        <div className="solution">
            <CodeComponent codeString={solution} />
        </div>
    );
};

export default SolutionPane;
