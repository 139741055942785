import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import Expander from "../Expander/Expander";
import "./ReaderPane.css";
const ReaderPane = ({ readme }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    return (
        <div className={`readme ${isFullscreen ? "fullscreen" : ""}`}>
            <ReactMarkdown children={readme}></ReactMarkdown>
            <Expander
                pane="reader"
                onClick={() => setIsFullscreen(!isFullscreen)}
                isFullscreen={isFullscreen}
            />
        </div>
    );
};

export default ReaderPane;
