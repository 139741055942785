import React from "react";
import MainLogo from "./MainLogo";
import "./AppBar.css";

const AppBar = () => {
    return (
        <div className="app-bar">
            <MainLogo />
        </div>
    );
};

export default AppBar;
