import React from "react";
import ProblemCard from "./ProblemCard";
import "./ProblemList.css";

const ProblemList = ({ problems, onProblemClick, activeProblem }) => {
    // const problems = [
    //     {
    //         id: 909,
    //         label: "Range sum of BST",
    //     },
    //     {
    //         id: 1351,
    //         label: "Backward Letter Detection",
    //     },
    //     {
    //         id: 2547,
    //         label: "Binary Search",
    //     },
    // ];
    const renderListMenu = () => {
        return <div className="problem-list-menu-item">Problems</div>;
    };
    const renderProblems = () => {
        return problems.map((item, index) => (
            <ProblemCard
                problem={item}
                key={index}
                onProblemClick={() => onProblemClick(item)}
                activeProblem={activeProblem?.id === item?.id ? true : false}
            />
        ));
    };
    return (
        <ul className="problem-list">
            {renderListMenu()}
            {renderProblems()}
        </ul>
    );
};

export default ProblemList;
